<template>
  <div class="footer_wrap">
    <!-- Footer Start -->
    <div class="flex-grow-1" />
    <div class="app-footer">
      <div
        class="
          footer-bottom
          border-top
          pt-3
          d-flex
          flex-column flex-sm-row
          align-items-center
        "
      >
        <div class="d-flex align-items-center">
          <img
            class="logo"
            src=""
            alt=""
          >
          <div>
            <div>
              <p class="m-0">
                &copy; 2022 Burenhulp Zielhorst/Schothorst v1.2.1
              </p>
              <p class="m-0">
                All rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- fotter end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
