<template>
  <div class="auth-layout-wrap">
    <div class="auth-content">
      <div class="card o-hidden">
        <div class="row">
          <div
            class="col-md-6 text-center "
            style="background-size: cover; background-color: #000000"
            :style="{ backgroundImage: 'url(' + signInImage + ')' }"
          >
            <div class="pl-3 auth-right">
              <div class="auth-logo text-center mt-4">
                <img
                  v-if="logo !== ''"
                  :src="logo"
                  alt=""
                >
              </div>
              <div class="flex-grow-1" />
              <div class="w-100 mb-30">
                <router-link
                  to="signIn"
                  class="btn btn-outline-primary btn-outline-email btn-block btn-icon-text btn-rounded"
                  href="signin.html"
                >
                  <i class="i-Mail-with-At-Sign" /> Sign in with Email
                </router-link>
              </div>
              <div class="flex-grow-1" />
            </div>
          </div>

          <b-col md="6">
            <div class="p-4">
              <h1 class="mb-3 text-18">
                Sign Up
              </h1>
              <b-form @submit.prevent="submit">
                <b-form-group label="Your Name">
                  <b-form-input
                    v-model.trim="$v.fName.$model"
                    class="form-control form-control-rounded"
                    label="Name"
                  />

                  <b-alert
                    v-if="!$v.fName.minLength"
                    show
                    variant="danger"
                    class="error col mt-1"
                  >
                    Name must have at least {{ $v.fName.$params.minLength.min }} letters.
                  </b-alert>
                </b-form-group>

                <b-form-group label="Email">
                  <b-form-input
                    v-model="email"
                    class="form-control form-control-rounded"
                    label="Name"
                    type="email"
                  />
                </b-form-group>

                <b-form-group label="Password">
                  <b-form-input
                    v-model.trim="$v.password.$model"
                    class="form-control form-control-rounded"
                    label="Name"
                    type="password"
                  />

                  <b-alert
                    v-if="!$v.password.minLength"
                    show
                    variant="danger"
                    class="error col mt-1"
                  >
                    Minimum {{ $v.password.$params.minLength.min }} charaters.
                  </b-alert>
                </b-form-group>

                <b-form-group label="Repeat Password">
                  <b-form-input
                    v-model.trim="$v.repeatPassword.$model"
                    class="form-control form-control-rounded"
                    label="Name"
                    type="password"
                  />

                  <b-alert
                    v-if="!$v.repeatPassword.sameAsPassword"
                    show
                    variant="danger"
                    class="error col mt-1"
                  >
                    Passwords must be identical.
                  </b-alert>
                </b-form-group>

                <b-button
                  type="submit"
                  block
                  variant="primary"
                  :disabled="submitStatus === 'PENDING' || $v.$invalid"
                  class="btn-rounded"
                >
                  Sign Up
                </b-button>

                <p
                  v-if="submitStatus === 'OK'"
                  v-once
                  class="typo__p"
                >
                  {{ makeToastTwo('success') }}
                  {{ this.$router.push('/') }}
                </p>
                <p
                  v-if="submitStatus === 'ERROR'"
                  v-once
                  class="typo__p"
                >
                  {{ makeToast('danger') }}
                </p>
                <div
                  v-if="submitStatus === 'PENDING'"
                  v-once
                  class="typo__p"
                >
                  <div class="spinner sm spinner-primary mt-3" />
                </div>
              </b-form>
            </div>
          </b-col>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Virus Onder Control Aanmelden',
  },

  data() {
    return {
      fName: '',
      email: '',
      bgImage: require('@/assets/images/photo-wide-2.jpg'),
      logo: '',
      signInImage: require('@/assets/images/photo-long-1.jpg'),
      password: '',
      repeatPassword: '',
      submitStatus: null,
    };
  },

  validations: {
    fName: {
      required,
      minLength: minLength(4),
    },

    password: {
      required,
      minLength: minLength(5),
    },
    repeatPassword: {
      sameAsPassword: sameAs('password'),
    },

    // add input
    // peopleAdd: {
    //   required,
    //   minLength: minLength(3),
    //   $each: {
    //     multipleName: {
    //       required,
    //       minLength: minLength(5)
    //     }
    //   }
    // },
    // validationsGroup:['peopleAdd.multipleName']
  },

  computed: {
    ...mapGetters(['loggedInUser', 'loading', 'error']),
  },

  methods: {
    ...mapActions(['signUserUp']),
    //   validate form
    submit() {
      console.log('submit!');

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.signUserUp({ email: this.email, password: this.password });
        this.submitStatus = 'PENDING';
        setTimeout(() => {
          this.submitStatus = 'OK';
        }, 1000);
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast('Please fill the form correctly.', {
        title: `Variant ${variant || 'default'}`,
        variant,
        solid: true,
      });
    },
    makeToastTwo(variant = null) {
      this.$bvToast.toast('Successfully Created Account', {
        title: `Variant ${variant || 'default'}`,
        variant,
        solid: true,
      });
    },

    inputSubmit() {
      console.log('submitted');
    },
  },
};
</script>
<style>
.spinner.sm {
  height: 2em;
  width: 2em;
}
</style>
