import Vue from 'vue';
import { HelpTypes } from '@/helpers';

Vue.filter('formatHelpTypes', (value: [string]) => {
  if (value) {
    return value.map((p) => HelpTypes.filter((q) => q.value === p).map((p) => p.text)).join(',');
  }
});

Vue.filter('formatHelpType', (value: string) => {
  if (value) {
    return HelpTypes.filter((q) => q.value === value)
      .map((p) => p.text)
      .join(',');
  }
});
