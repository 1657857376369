import Vue from 'vue';
import { RequestStatus } from '@/helpers';

Vue.filter('formatRequestStatus', (value: string) => {
  if (value) {
    return RequestStatus.filter((p) => p.value === value)
      .map((p) => p.text)
      .join(',');
  }
});
