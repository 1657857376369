import Vue from 'vue';
import { UserStatus } from '@/helpers';

Vue.filter('formatUserStatus', (value: string) => {
  if (value) {
    return UserStatus.filter((p) => p.value === value)
      .map((p) => p.text)
      .join(',');
  }
});
