import { BehaviorSubject } from 'rxjs';

import { requestOptions, handleResponse } from '@/helpers';

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentUser') || '{}'),
);

function login(username: string, password: string) {
  return fetch('api/auth/user/token', requestOptions.post({ email: username, password }))
    .then(handleResponse)
    .then((user: any) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(user.data));
      currentUserSubject.next(user.data);

      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
}

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};
