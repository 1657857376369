import { authenticationService } from '@/services';

function headers(): any {
  const currentUser = authenticationService.currentUserValue || {};
  const headers = {
    'Content-Type': 'application/json',
    Authorization: '',
  };
  if (currentUser.token) {
    headers.Authorization = `Bearer ${currentUser.token}`;
  }
  return {
    headers,
  };
}

export const requestOptions = {
  get(): any {
    return {
      method: 'GET',
      ...headers(),
    };
  },
  post(body: any): any {
    return {
      method: 'POST',
      ...headers(),
      body: JSON.stringify(body),
    };
  },
  patch(body: any): any {
    return {
      method: 'PATCH',
      ...headers(),
      body: JSON.stringify(body),
    };
  },
  put(body: any): any {
    return {
      method: 'PUT',
      ...headers(),
      body: JSON.stringify(body),
    };
  },
  delete(): any {
    return {
      method: 'DELETE',
      ...headers(),
    };
  },
};
