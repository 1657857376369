import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { Roles } from '@/helpers';
import { authenticationService } from '@/services';

import Signup from '@/views/Signup.vue';
import Signin from '@/views/Signin.vue';
import Forgot from '@/views/Forgot.vue';
import Forbiden from '@/views/Forbiden.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/signIn',
  },
  {
    path: '/signUp',
    name: 'signUp',
    component: Signup,
  },
  {
    path: '/signIn',
    name: 'signIn',
    component: Signin,
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: Forgot,
  },
  {
    path: '/home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/Home.vue'),
    children: [
      {
        path: '',
        name: 'Persons',
        component: () => import('@/views/ParticipantsPage.vue'),
        meta: { authorize: [Roles.Admin, Roles.ContactPerson] },
      },
      {
        path: '/archive',
        name: 'Archive',
        component: () => import('@/views/ParticipantsArchivePage.vue'),
        meta: { authorize: [Roles.Admin] },
      },
      {
        path: '/caregivers',
        name: 'Caregivers',
        component: () => import('@/views/CaregiverPage.vue'),
        meta: { authorize: [Roles.Admin, Roles.ContactPerson] },
      },
      {
        path: '/matches',
        name: 'Matches',
        component: () => import('@/views/MatchesPage.vue'),
        meta: { authorize: [Roles.Admin, Roles.ContactPerson] },
      },
      {
        path: '/carereceivers',
        name: 'Carereceivers',
        component: () => import('@/views/CarereceiverPage.vue'),
        meta: { authorize: [Roles.Admin, Roles.ContactPerson] },
      },
      {
        path: '/participants/:id',
        name: 'Participants',
        component: () => import('@/views/ParticipantPage.vue'),
        meta: { authorize: [Roles.Admin, Roles.ContactPerson] },
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import('@/views/UsersPage.vue'),
        meta: { authorize: [Roles.Admin] },
      },
      {
        path: '/users/:id',
        name: 'User',
        component: () => import('@/views/UserPage.vue'),
        meta: { authorize: [Roles.Admin] },
      },
      {
        path: '/forbiden',
        name: 'Forbiden',
        component: Forbiden,
      },
    ],
    meta: { authorize: [Roles.Admin, Roles.User, Roles.ContactPerson] },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;
  const currentUser = authenticationService.currentUserValue;

  if (authorize) {
    if (!currentUser.token) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/signIn', query: { returnUrl: to.path } });
    }

    // check if route is restricted by role
    if (
      authorize.length
      && !currentUser.roles.filter((value: string) => authorize.includes(value)).length
    ) {
      // role not authorised so redirect to home page
      return next({ path: '/' });
    }
  }
  next();
});

export default router;
