import Vue from 'vue';
import { Salutation } from '@/helpers';

Vue.filter('formatSalutation', (value: string) => {
  if (value) {
    return Salutation.filter((p) => p.value === value)
      .map((p) => p.text)
      .join(',');
  }
});
