import Vue from 'vue';
import { YesNo } from '@/helpers';

Vue.filter('formatYesNo', (value: string) => {
  if (value) {
    return YesNo.filter((p) => p.value === value)
      .map((p) => p.text)
      .join(',');
  }
});
