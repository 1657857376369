<template>
  <div class="main-content">
    <b-jumbotron
      bg-variant="danger"
      text-variant="white"
      border-variant="dark"
    >
      <template v-slot:header>
        Geen toegang
      </template>

      <template v-slot:lead>
        U heeft geen toegang tot deze functionaliteit.
      </template>

      <hr class="my-4">

      <p>
        Deze site is alleen bedoeld voor specifieke leden van het Burenhulp kern-team.
      </p>
    </b-jumbotron>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
