import { handleResponse, requestOptions } from '@/helpers';

const getAll = (): Promise<any> => fetch('api/clients?hideDeleted=0', requestOptions.get()).then(handleResponse);

const getActive = (): Promise<any> => fetch('api/clients?hideDeleted=1', requestOptions.get()).then(handleResponse);

const getInactive = (): Promise<any> => fetch('api/clients?showDeleted=1&hideDeleted=0', requestOptions.get()).then(handleResponse);

const getById = (id: string): Promise<any>  => fetch(`api/clients/${id}`, requestOptions.get()).then(handleResponse);

function save(participant: any): Promise<any>  {
  if (participant._id) {
    return fetch(`api/clients/${participant._id}`, requestOptions.put(participant)).then(
      handleResponse,
    );
  }
  return fetch('api/clients', requestOptions.post(participant)).then(handleResponse);
}

function remove(id: string): Promise<any>  {
  return fetch(`api/clients/${id}`, requestOptions.delete()).then(handleResponse);
}

function anonymize(id: string): Promise<any>  {
  return fetch(`api/clients/${id}/anonymize`, requestOptions.put({})).then(handleResponse);
}

function getAllCaregivers(): Promise<any> {
  return fetch('api/events', requestOptions.get()).then(handleResponse);
}

function getCaregiverItems(participant: any): Promise<any>  {
  return fetch(`api/events?person=${participant._id}`, requestOptions.get()).then(handleResponse);
}

function saveCaregiverItem(caregiverItem: any): Promise<any>  {
  if (caregiverItem._id) {
    return fetch(`api/events/${caregiverItem._id}`, requestOptions.put(caregiverItem)).then(
      handleResponse,
    );
  }
  return fetch('api/events', requestOptions.post(caregiverItem)).then(handleResponse);
}

function removeCaregiverItem(caregiverItem: any): Promise<any>  {
  return fetch(`api/events/${caregiverItem._id}`, requestOptions.delete()).then(handleResponse);
}

function getAllCareRecipients(): Promise<any>  {
  return fetch('api/articles', requestOptions.get()).then(handleResponse);
}

function getCareRecipientsItems(participant: any): Promise<any>  {
  return fetch(`api/articles?person=${participant._id}`, requestOptions.get()).then(handleResponse);
}

function saveCareRecipientItem(careRecipientItem: any): Promise<any>  {
  if (careRecipientItem._id) {
    return fetch(
      `api/articles/${careRecipientItem._id}`,
      requestOptions.put(careRecipientItem),
    ).then(handleResponse);
  }
  return fetch('api/articles', requestOptions.post(careRecipientItem)).then(handleResponse);
}

function removeCareRecipientItem(careRecipientItem: any): Promise<any>  {
  return fetch(`api/articles/${careRecipientItem._id}`, requestOptions.delete()).then(
    handleResponse,
  );
}

function getMatchItems(participant: any): Promise<any>  {
  return fetch(`api/matches?person=${participant._id}`, requestOptions.get()).then(handleResponse);
}

function getAllMatches(participant: any): Promise<any>  {
  return fetch('api/matches', requestOptions.get()).then(handleResponse);
}

function getAvailableCaregiverItems(helptype: string): Promise<any>  {
  return fetch(`api/available?helptype=${helptype}`, requestOptions.get()).then(handleResponse);
}

export const participantsService = {
  getAll,
  getActive,
  getInactive,
  getById,
  save,
  remove,
  anonymize,
  getCaregiverItems,
  saveCaregiverItem,
  removeCaregiverItem,
  getCareRecipientsItems,
  saveCareRecipientItem,
  removeCareRecipientItem,
  getMatchItems,
  getAvailableCaregiverItems,
  getAllCaregivers,
  getAllCareRecipients,
  getAllMatches,
};
