import { handleResponse, requestOptions } from '@/helpers';

function getAll() {
  return fetch('api/users', requestOptions.get()).then(handleResponse);
}

function getById(id: string) {
  return fetch(`api/users/${id}`, requestOptions.get()).then(handleResponse);
}

function save(user: { _id: { type: string } }) {
  if (user._id) {
    return fetch(`api/users/${user._id}`, requestOptions.put(user)).then(handleResponse);
  }
  return fetch('api/users', requestOptions.post(user)).then(handleResponse);
}

function remove(user: { _id: { type: string } }) {
  return fetch(`api/users/${user._id}`, requestOptions.delete()).then(handleResponse);
}

export const usersService = {
  getAll,
  getById,
  save,
  remove,
};
