export const RequestStatus = [
  {
    value: '1',
    text: 'Open',
  },
  {
    value: '2',
    text: 'Gekoppeld',
  },
  {
    value: '3',
    text: 'Niet in te vullen',
  },
  {
    value: '4',
    text: 'Afgehandeld',
  },
  {
    value: '5',
    text: 'Niet doorgegaan',
  },
];

export const HelpTypes = [
  {
    value: '1',
    text: 'Sociale hulp',
  },
  {
    value: '2',
    text: 'Klussen',
  },
  {
    value: '3',
    text: 'Tuinieren',
  },
  {
    value: '4',
    text: 'Boodschappen doen',
  },
  {
    value: '5',
    text: 'Vervoer',
  },
  {
    value: '7',
    text: 'PC problemen oplossen',
  },
  {
    value: '8',
    text: 'Mobiel problemen oplossen',
  },
  {
    value: '9',
    text: 'Administratie regelen',
  },
  {
    value: '13',
    text: 'TV of Audio problemen',
  },
  {
    value: '11',
    text: 'Helpen met verhuizen',
  },
  {
    value: '12',
    text: 'Overig',
  },
];

export const YesNo = [
  {
    value: 'yes',
    text: 'Ja',
  },
  {
    value: 'no',
    text: 'Nee',
  },
];

export const Duration = [
  {
    value: '1',
    text: 'Nee',
  },
  {
    value: '2',
    text: 'Ja',
  },
];

export const RolesNames = [
  {
    value: 'admin',
    text: 'Kernteamlid',
  },
  {
    value: 'contactperson',
    text: 'Contactpersoon',
  },
];

export const UserStatus = [
  { value: 'not-verified', text: 'Niet gevalideerd' },
  { value: 'verified', text: 'Gevalideerd' },
  { value: 'blocked', text: 'Geblokkeerd' },
  { value: 'unavailable', text: 'Niet beschikbaar' },
  { value: 'unsubscribed', text: 'Uitgeschreven' },
];

export const Salutation = [
  {
    value: 'mr',
    text: 'De heer',
  },
  {
    value: 'mrs',
    text: 'Mevrouw',
  },
  {
    value: 'none',
    text: 'Geen',
  }
];

export const Frequency = [
  {
    value: 'none',
    text: 'Eenmalig',
  },
  {
    value: '1pw',
    text: '1x per week',
  },
  {
    value: '1p2w',
    text: '1x per 2 weken',
  },
  {
    value: '1pm',
    text: '1x per maand',
  },
  {
    value: '1pk',
    text: '1x per kwartaal',
  }
];
