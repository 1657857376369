<template>
  <div class="app-admin-wrap layout-sidebar-large clearfix">
    <appHeader />
    <main>
      <div class="main-content-wrap d-flex flex-column">
        <transition
          name="page"
          mode="out-in"
        >
          <router-view />
        </transition>
      </div>
    </main>
    <!-- appFooter /-->
    <appFooter />
  </div>
</template>
<script>
import appHeader from './header.vue';
import appFooter from './footer.vue';

export default {
  components: {
    appHeader,
    appFooter,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
<style></style>
